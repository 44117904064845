function FormAutoReplyEmailerHtmlEmailTemplate() {
    return (
        `
<!DOCTYPE html>
<html>
    <head>
    <base target="_top">
    <style>
        body {
        background-color: #f0f0f0;
        }
        a {
        text-decoration: none;
        }
        .container {
            padding: 20px 0px;
            font-size: 16px;
        }
        .header-section {
            padding: 10px;
            margin: 10px;
            vertical-align: middle;
            background-color: #f9fc88;
        }
        .footer {
        padding: 10px;
        margin: auto;
        vertical-align: middle;
        background-color: #f9fc88;
        }
        .footer-content {
        text-align: center;
        }
    </style>
    </head>
    <body>
        <? if (data.logo !== '') { ?>
            <section class="header-section">
                <a href=<?= data.url ?>>
                <img class="logo" src=<?= data.logo ?> height="60px" width="auto" />
                </a>
                <strong style="font-weight: 700; font-size: 18px; vertical-align: middle; margin: auto 0;">&nbsp;<?= data.companyName ?></strong>
            </section>
        <? } ?>
        <div class="container" style="width: 70%; margin: auto;">
            <p style="padding-bottom: 15px;">
                <? if (data.recipientName !== '') { ?>
                    Hi <?= data.recipientName ?>,
                <? } else { ?>
                    Hi <?= data.recipientEmail ?>,
                <? } ?>
            </p>
            <? let paragraphs = data.body.split('\n') ?>
            <? for (let item in paragraphs) { ?> 
                <p>
                    <?= paragraphs[item] ?>
                </p>
            <? } ?>
        </div>
        <? if (data.logo !== '') { ?>
            <footer class="footer" style="padding-top: 15px;">
                <div class="footer-content">
                <a href=<?= data.url ?>>
                    <img class="logo" src=<?= data.logo ?> height="25px" width="auto" />
                </a>
                </div>
            </footer>
        <? } ?>
    </body>
</html>               
`
    )
}

export default FormAutoReplyEmailerHtmlEmailTemplate